import React, {useEffect, useState, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LoginPage from "./screens/LoginPage";
import RegisterPage from "./screens/RegisterPage";
import ResetPage from "./screens/ResetPage";
import HomePage from "./screens/HomePage";
import ProfilePage from "./screens/ProfilePage";
import Header from "./components/Header";
import { logout } from "./actions/auth";
import { history } from "./helpers/history";
import EventBus from "./common/EventBus";
import AuthVerify from "./common/AuthVerify";
import BoardUser from "./screens/BoardUser";
import BoardSupervisor from "./screens/BoardSupervisor";
import BoardAdmin from "./screens/BoardAdmin";
import ResetPassword from "./screens/ResetPassword";
import BoardOperator from "./screens/BoardOperator";
import Notification from "./screens/Notification";
import Page404 from "./pages/Page404";

import PrivateRoute from "./helpers/PrivateRoute";
import ResetPrivateRoute from "./helpers/ResetPrivateRoute";
import {getNotifications} from "./actions/admin.action";

const App = () => {
  const store = useSelector((store) => store);
  const { user: currentUser } = useSelector((state) => state.auth);
  // const operator = currentUser?.roles?.includes("ROLE_OPERATOR");
  // const admin = currentUser?.roles?.includes("ROLE_ADMIN");
  // const user = currentUser?.roles?.includes("ROLE_USER");
  const [showOperatorBoard, setShowOperatorBoard] = useState(false);
  const [showSupervisorBoard, setShowSupervisorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showUserBoard, setShowUserBoard] = useState(false);
  
  const dispatch = useDispatch();


const logOut = useCallback(() => {
  dispatch(logout());
}, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowOperatorBoard(currentUser.roles.includes("ROLE_OPERATOR"));
      setShowSupervisorBoard(currentUser.roles.includes("ROLE_SUPERVISOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setShowUserBoard(currentUser.roles.includes("ROLE_USER"));
    } else {
      setShowSupervisorBoard(false);
      setShowOperatorBoard(false);
      setShowAdminBoard(false);
      setShowUserBoard(false);
    }
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);


useEffect(() => {
  if (showAdminBoard) {
    dispatch(getNotifications());
setInterval(() => {
  dispatch(getNotifications());
}, 300000);
  }
}, [dispatch, showAdminBoard]);

  // console.log("Operator:", showOperatorBoard);
  // console.log("Admin:", showAdminBoard);
  // console.log("User:", user);
  // console.log("State User:", currentUser);
  //console.log(store);
  return (
    <Router history={history}>
      <div>
        <Header />
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path={["/", "/home"]} component={HomePage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/resetMyPassword" component={ResetPage} />
          <PrivateRoute
            exact
            path="/user"
            isAuth={showUserBoard}
            component={BoardUser}
          />
          <PrivateRoute
            exact
            path="/operator"
            component={BoardOperator}
            isAuth={showOperatorBoard}
          />
          <PrivateRoute
            exact
            path="/supervisor"
            component={BoardSupervisor}
            isAuth={showSupervisorBoard}
          />
          <PrivateRoute
            exact
            path="/notification"
            component={Notification}
            isAuth={showAdminBoard}
          /> 
          <PrivateRoute
            exact
            path="/admin"
            component={BoardAdmin}
            isAuth={showAdminBoard}
          />
          <ResetPrivateRoute
            path="/passwordReset*"
            component={ResetPassword}
            isAuth={showAdminBoard}
          />
          <Route path="*" component={Page404} />
        </Switch>
        <AuthVerify logOut={logout} />
      </div>
    </Router>
  );
};

export default App;
