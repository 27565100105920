import axios from "axios";
import { API_url } from '../config/config'
import { logout } from "./auth";


import {
  CONTRACT_GET_SUCCESS,
  CONTRACT_GET_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  CONTRACT_DELETE_FAIL,
  CONTRACT_ADD_FAIL
} from "./types";

  export const addNewContract = (state) => async (dispatch, getState) => {
    //console.log("add new contract calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.post(`${API_url}/api/contract`, state, config);
      //console.log(data)
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }, 3000);
      return data.status;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CONTRACT_ADD_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };

  export const getUserContract = () => async (dispatch, getState) => {
    //console.log("get oneuser contract calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.get(`${API_url}/api/contract?sort=-hireDate`, config);
      //console.log(data)
      dispatch({
        type: CONTRACT_GET_SUCCESS,
        payload: data.contracts,
      });
      return data.status;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CONTRACT_GET_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };

  export const updateUserContract = (state) => async (dispatch, getState) => {
  //  console.log("update user contract calıstı")
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.put(`${API_url}/api/contract/${state._id}`, state, config);
      //console.log(data)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error.response ? error.response.data : error;
    }
  };
  
  export const deleteUserContract = (id) => async (dispatch, getState) => {
   // console.log("delete user contract calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.delete(`${API_url}/api/contract/${id}`, config);
     // console.log(data)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error.response ? error.response.data.message : error;
    }
  }