import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Page404 from "../pages/Page404";

function ResetPrivateRoute({ isAuth: isAuth, component: Component, ...rest }) {
  const [showComponent, setshowComponent] = useState(false);

  const currentHref = window.location.href;
  //console.log("currentHref =", currentHref);


  if (
    currentHref.includes("token=") &
    currentHref.includes("id=") &
    (showComponent === false)
  ) {
    setshowComponent(true);
    //console.log("showComponent=>", showComponent);
  }

  return (
    <>
      {showComponent && (
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
        // <Route
        //   {...rest}
        //   render={(props) => {
        //     if (isAuth) {
        //       return <Component {...props} />;
        //     } else {
        //       return (
        //         <Redirect
        //           to={{ pathname: "/", state: { from: props.location } }}
        //         />
        //       );
        //     }
        //   }}
        // />
      )}
    </>
  );
}

export default ResetPrivateRoute;
