import {
  USER_ALL_SUCCESS,
  USER_ALL_FAIL,
  CONTRACT_ALL_SUCCESS,
  CONTRACT_ALL_FAIL,
  DOCUMENT_ALL_SUCCESS,
  DOCUMENT_ALL_FAIL,
  ROLE_ALL_SUCCESS,
  ROLE_ALL_FAIL,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,
  LOGOUT
} from "../actions/types";


export default function (state = {users:[] , contracts:[], roles:[], documents:[], notifications:[]}, action) {
  const { type, payload } = action;
  //console.log(payload)
  switch (type) {
    case USER_ALL_SUCCESS:
      return {
        ...state,
        users: payload
      };
    case USER_ALL_FAIL:
      return state;
    case CONTRACT_ALL_SUCCESS:
      return {
        ...state,
        contracts: payload
      };
    case CONTRACT_ALL_FAIL:
      return state;
    case DOCUMENT_ALL_SUCCESS:
      return {
        ...state,
        documents: payload
      };
    case DOCUMENT_ALL_FAIL:
      return state;
    case ROLE_ALL_SUCCESS:
      return {
        ...state,
        roles: payload
      };
    case ROLE_ALL_FAIL:
      return state;
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: payload
      };
    case NOTIFICATION_FAIL:
      return state;
      case LOGOUT:
      return {
        users: [],
        contracts: [],
        roles:[],
        documents:[],
        notifications:[]
      };
    default:
      return state;
  }
}
