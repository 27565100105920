export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS";
export const CONTACT_FORM_FAIL = "CONTACT_FORM_FAIL";

export const USER_ALL_SUCCESS = "USER_ALL_SUCCESS";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAIL = "USER_GET_FAIL";
export const USER_ALL_FAIL = "USER_ALL_FAIL";

export const CONTRACT_GET_SUCCESS = "CONTRACT_GET_SUCCESS";
export const CONTRACT_ALL_SUCCESS = "CONTRACT_ALL_SUCCESS";
export const CONTRACT_GET_FAIL = "CONTRACT_GET_FAIL";
export const CONTRACT_ALL_FAIL = "CONTRACT_ALL_FAIL";
export const CONTRACT_ADD_FAIL = "CONTRACT_ADD_FAIL";
export const CONTRACT_DELETE_FAIL = "CONTRACT_DELETE_FAIL";

export const DOCUMENT_ALL_SUCCESS = "DOCUMENT_ALL_SUCCESS";
export const DOCUMENT_ALL_FAIL = "DOCUMENT_ALL_FAIL";
export const DOCUMENT_GET_FAIL = "DOCUMENT_GET_FAIL";
export const DOCUMENT_GET_SUCCESS = "DOCUMENT_GET_SUCCESS";
export const DOCUMENT_DELETE_FAIL = "DOCUMENT_DELETE_FAIL";
export const DOCUMENT_ADD_FAIL = "DOCUMENT_ADD_FAIL";

export const ROLE_ALL_SUCCESS = "ROLE_ALL_SUCCESS";
export const ROLE_ALL_FAIL = "ROLE_ALL_FAIL";

export const GENERATE_GET_FAIL = "GENERATE_GET_FAIL";
export const GENERATE_GET_SUCCESS = "GENERATE_GET_SUCCESS";
export const GENERATE_ALL_SUCCESS = "GENERATE_ALL_SUCCESS";
export const GENERATE_ALL_FAIL = "GENERATE_ALL_FAIL";

export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const USER_MODE_SUCCESS = "USER_MODE_SUCCESS"; 
export const MOD_USER_SUCCESS = "MOD_USER_SUCCESS"; 
export const MOD_USER_FAIL = "MOD_USER_FAIL"; 

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";

//Operator Page Actions
export const SEND_DATA_REQUEST = "SEND_DATA_REQUEST";
export const SEND_DATA_SUCCESS = "SEND_DATA_SUCCESS";
export const SEND_DATA_FAILED = "SEND_DATA_FAILED";

