import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../styles/header.css";
import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";

import { history } from "../helpers/history";
// import EventBus from "../common/EventBus";

export default function Header() {
  const dispatch = useDispatch();
  const [showOperatorBoard, setShowOperatorBoard] = useState(false);
  const [showSupervisorBoard, setShowSupervisorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showUserBoard, setShowUserBoard] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { notifications } = useSelector((store) => store.admins);
  const active =
    notifications && notifications.filter((item) => item.isActive === true);

  const { user: currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    history.listen((location) => {
      //console.log("header içi history listen");
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowOperatorBoard(currentUser.roles.includes("ROLE_OPERATOR"));
      setShowSupervisorBoard(currentUser.roles.includes("ROLE_SUPERVISOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      setShowUserBoard(currentUser.roles.includes("ROLE_USER"));
    } else {
      setShowSupervisorBoard(false);
      setShowOperatorBoard(false);
      setShowAdminBoard(false);
      setShowUserBoard(false);
    }

    // EventBus.on("logout", () => {
    //   logOut();
    //   //props.history.push("/");
    // });

    // return () => {
    //   EventBus.remove("logout");
    // };
  }, [currentUser]);

  return (
    <Navbar
      className="nav-container navbar my-0"
      variant="dark"
      expand="lg"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src="images/logo_banner.png"
            height="70"
            className="d-inline-block align-top"
            alt=""
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ zIndex: 1 }}
          className="text-center ps-3 "
        >
          <Container id="navItems" className="d-xl-inline-flex">
            <Nav className="me-auto">
              <Nav.Item>
                <LinkContainer to="/">
                  <Nav.Link
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                  >
                    Home
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="/#about"
                  onClick={() => setExpanded(expanded ? false : "expanded")}
                >
                  About Us
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="/#services"
                  onClick={() => setExpanded(expanded ? false : "expanded")}
                >
                  Services
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="/#contact"
                  onClick={() => setExpanded(expanded ? false : "expanded")}
                >
                  Contact
                </Nav.Link>
              </Nav.Item>

              {/* Only Admin Pages */}
              {showAdminBoard && (
                <>
                  <Nav.Item>
                    <LinkContainer
                      to="/notification"
                      style={{ color: "white" }}
                    >
                      <Nav.Link
                        onClick={() =>
                          setExpanded(expanded ? false : "expanded")
                        }
                      >
                        Notice{" "}
                        {active && active.length > 0 && (
                          <Badge bg="info">{active && active.length}</Badge>
                        )}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <LinkContainer to="/admin" style={{ color: "white" }}>
                      <Nav.Link
                        onClick={() => {
                          setExpanded(expanded ? false : "expanded");
                        }}
                      >
                        Admin Panel
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </>
              )}
              {showOperatorBoard && (
                <Nav.Item>
                  <LinkContainer to="/operator" style={{ color: "white" }}>
                    <Nav.Link
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Operator
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              )}
              {showSupervisorBoard && (
                <Nav.Item>
                  <LinkContainer to="/supervisor" style={{ color: "white" }}>
                    <Nav.Link
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      Supervisor
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              )}
              {currentUser && !showOperatorBoard && (
                  <Nav.Item>
                    <LinkContainer to="/user" className="">
                      <Nav.Link
                        className="text-center pe-2"
                        onClick={() =>
                          setExpanded(expanded ? false : "expanded")
                        }
                      >
                        Profile
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  )}
                  {currentUser && (
                  <NavDropdown
                    title={
                      currentUser &&
                      currentUser.email &&
                      currentUser.email.split("@")[0]
                    }
                    id="basic-nav-dropdown"
                  >
                    <Container className="" id="dropDownItems">
                      <LinkContainer to="/" onClick={logOut} className="">
                        <Nav.Link
                          className="text-center pe-2"
                          onClick={() =>
                            setExpanded(expanded ? false : "expanded")
                          }
                        >
                          Log Out
                        </Nav.Link>
                      </LinkContainer>
                    </Container>
                  </NavDropdown>
              )}
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
