import {
  DOCUMENT_GET_SUCCESS,
  DOCUMENT_GET_FAIL,
  LOGOUT
} from "../actions/types";


export default function (state = {current:null }, action) {
  const { type, payload } = action;
 // console.log(payload)
  switch (type) {
    case DOCUMENT_GET_SUCCESS:
      return {
        current: payload
      };
    case DOCUMENT_GET_FAIL:
      return state;
      case LOGOUT:
      return {
        current: null,
      };
    default:
      return state;
  }
}
