import axios from "axios";
import { API_url } from '../config/config'
import { setMessage, clearMessage } from "./message";
import { logout } from "./auth";


import {
  USER_ALL_SUCCESS,
  USER_ALL_FAIL,
  CONTRACT_ALL_SUCCESS,
  CONTRACT_ALL_FAIL,
  GENERATE_ALL_SUCCESS,
  GENERATE_ALL_FAIL,
  CHANGE_STATUS_FAIL,
  ROLE_ALL_SUCCESS,
  ROLE_ALL_FAIL,
  DOCUMENT_ALL_SUCCESS,
  DOCUMENT_ALL_FAIL,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,
} from "./types";
  
  export const getAllUsers = (page, searchValue, status) => async (dispatch, getState) => {
    //console.log("get all users calıstı ==>", page, searchValue, status)
    let searchStatus = status === "true" ? true : false;
    //console.log(searchStatus)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
     const searchQuery = {
        "filter":searchValue.toLowerCase(),
        "status":searchStatus,
        "limit":10,
        "skip":10*(page-1)
    }
    
    
      const { data } = await axios.post(`${API_url}/api/allUsers`, searchQuery, config );
      //console.log(data)
      dispatch({
        type: USER_ALL_SUCCESS,
        payload: data.data,
      });
      return data.meta;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: USER_ALL_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const getOneUser = (id) => async (dispatch, getState) => {
    //console.log("get one user calıstı ==>", id)
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.get(`${API_url}/api/user/${id}`, config );
      //console.log(data)
      return data;
    } catch (error) {
      return error;
    }
  };
  
  export const getAllContracts = (page, searchValue) => async (dispatch, getState) => {
    //console.log("get all contracts calıstı ==>", page, searchValue)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };

      const { data } = await axios.get(`${API_url}/api/contractAll?company[regex]=${searchValue.toLowerCase()}&page=${page}`, config );
      //console.log(data)
      dispatch({
        type: CONTRACT_ALL_SUCCESS,
        payload: data.contracts,
      });
      return data.total;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CONTRACT_ALL_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const getAllDocuments = (page, searchValue) => async (dispatch, getState) => {
    //console.log("get all documents calıstı ==>", page, searchValue)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };

      const { data } = await axios.get(`${API_url}/api/documentAll?name[regex]=${searchValue.toLowerCase()}&page=${page}`, config );
     //console.log(data)
      dispatch({
        type: DOCUMENT_ALL_SUCCESS,
        payload: data.documents,
      });
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: DOCUMENT_ALL_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };

  export const getNotifications = (page, searchValue) => async (dispatch, getState) => {
    //console.log("get notifications==>", page, searchValue)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const { data } = await axios.get(`${API_url}/api/notifications`, config );
      //console.log(data)
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: data.notifications,
      });
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error.response ? error.response.data.message : error;
    }
  };

  export const changeNoticeStatus = (id, isActive, status) => async (dispatch, getState) => {
    //console.log("change notification calıstı ==>", id, isActive, status)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const {data}  = await axios.put(`${API_url}/api/notification/${id}`, {isActive:isActive, status:status}, config );
     //console.log(data)
      setMessage(data.msg)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };
  
  export const deleteNotification = (id) => async (dispatch, getState) => {
    //console.log("delete notification calıstı ==>", id)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const {data}  = await axios.delete(`${API_url}/api/notification/${id}`, config );
     //console.log(data)
      setMessage(data.msg)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };

  export const getAllRoles = (page, searchValue) => async (dispatch, getState) => {
    //console.log("get all roles calıstı ==>", page, searchValue)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const { data } = await axios.get(`${API_url}/api/role`, config );
      //console.log(data)
      dispatch({
        type: ROLE_ALL_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      //console.log(error.response)
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: ROLE_ALL_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const changeUserRole = (id, roles) => async (dispatch, getState) => {
    //console.log("change user roles calıstı ==>", id, roles)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const {data}  = await axios.put(`${API_url}/api/role/${id}`, {role_id:roles}, config );
     // console.log(data)
      setMessage(data.msg)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CHANGE_STATUS_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const changeUserStatus = (id) => async (dispatch, getState) => {
   // console.log("change user status calıstı ==>", id)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const { data } = await axios.put(`${API_url}/api/status/${id}`, {}, config );
      //console.log(data)
      setMessage(data)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CHANGE_STATUS_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const changeUserVisible = (id) => async (dispatch, getState) => {
    //console.log("change user operator status calıstı ==>", id)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const { data } = await axios.put(`${API_url}/api/showoperator/${id}`, {}, config );
      //console.log(data)
      setMessage(data)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: CHANGE_STATUS_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  
  export const getGeneratedCodes = (page, searchValue) => async (dispatch, getState) => {
   // console.log("get all generated codes calıstı ==>", page, searchValue)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const { data } = await axios.get(`${API_url}/api/generateGetAll`, config );
      //console.log(data)
      dispatch({
        type: GENERATE_ALL_SUCCESS,
        payload: data,
      });
      return data.length;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: GENERATE_ALL_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };

  export const sendUsertoHR = (id, email) => async (dispatch, getState) => {
    //console.log("send user to HR calıstı ==>", id, email)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const {data}  = await axios.post(`${API_url}/api/sendUserCode/${id}`, {email}, config );
      //console.log(data)
      setMessage(data.msg)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };
  
  export const sendUserConfirmation = (id, email) => async (dispatch, getState) => {
    //console.log("send user confirmation calıstı ==>", id, email)
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      
      const {data}  = await axios.post(`${API_url}/api/sendConfirmation/${id}`, {email}, config );
      //console.log(data)
      setMessage(data.msg)
      setTimeout(() => {
        clearMessage();
      }, 3000)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };