import axios from "axios";
import { API_url } from '../config/config'
import { logout } from "./auth";


import {
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE
} from "./types";
  
  export const getOneUser = () => async (dispatch, getState) => {
    //console.log("getoneuser calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const { data } = await axios.get(`${API_url}/api/user`, config);
      //console.log(data)
      dispatch({
        type: USER_GET_SUCCESS,
        payload: data.user,
      });
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: USER_GET_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  export const updateUser = (state) => async (dispatch, getState) => {
    //console.log("update user calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`
        },
      }; 
      const { data } = await axios.put(`${API_url}/api/user/update`, state, config);
     //console.log (data)
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }, 3000);
      return data.status;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: USER_GET_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error;
    }
  };
  export const updateUserPassword = (state) => async (dispatch, getState) => {
    //console.log("update user password calıstı")
    try {
      const {
        auth: { user },
      } = getState();
  
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`
        },
      }; 
      const { data } = await axios.put(`${API_url}/api/user/update-pass`, state, config);
     // console.log(data)
      dispatch({
        type: SET_MESSAGE,
        payload: data.message,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
        });
      }, 3000);
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      dispatch({
        type: USER_GET_FAIL,
        payload: error.response && error.response.data.message,
      });
      return error.response ? error.response : error;
    }
  };
  
  export const getProfileImage = (imgUrl) => async (dispatch, getState) => {
    //console.log("get profile img calıstı", imgUrl)
    try {
      const {
        auth: { user },
      } = getState();
      //Header to send with the request
      const config = {
        headers: {
          'x-access-token': `${user.accessToken}`,
        },
      };
      const  {data}  = await axios.get(`${API_url}/api/file/${imgUrl}`, config);
      //console.log(data)
      return data;
    } catch (error) {
      if(error.response && error.response.status === 401){
        dispatch(logout());
      }
      return error;
    }
  };

