import { combineReducers } from "redux";
import users from "./users";
import contracts from "./contracts";
import documents from "./documents";
import auth from "./auth";
import admins from "./admins";
import message from "./message";

export default combineReducers({
  documents,
  contracts,
  users,
  auth,
  admins,
  message,
});
