import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import ParticlesBg from 'particles-bg'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const LoginPage = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#1c262f";
  });

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state);

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then(() => {
          props.history.push("/");
          // window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <ParticlesBg color="#4780a9" num={50} type="cobweb" bg={true} />
      <div className="col-md-12">
        <div
          className="card card-container rounded mt-5"
          style={{ backgroundColor: "#17344b" }}
        >
          <img src="/images/logo.png" alt="" className="mb-4 mt-4" />
          <Form onSubmit={handleLogin} ref={form}>
            <div
              className="form-group fw-bolder mb-3"
              style={{ color: "#ef7d1a" }}
            >
              <label htmlFor="email" className="mb-1">
                Username
              </label>
              <Input
                type="text"
                className="form-control mb-2"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required]}
              />
            </div>
            <div className="form-group fw-bolder">
              <label
                htmlFor="password"
                className="mb-1"
                style={{ color: "#ef7d1a" }}
              >
                Password
              </label>
              <Input
                type="password"
                className="form-control mb-2"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
            <div className="form-group d-grid gap-2 my-3">
              <button
                className="btn form-control-submit-button"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
          <div>
            <Link className="mt-3 mb-1 float-start" to="/resetMyPassword">
              Forgot Password
            </Link>
            <Link className="mt-3 mb-1 float-end " to="/register">
              Create Account
            </Link>
          </div>
          <div>
            <a className="mt-3 mb-1 text-center" href="https://transcend.my.salesforce.com/" target="_blank">
              Are you an employee? Login here
            </a>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
