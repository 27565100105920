import {
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_MODE_SUCCESS,
  LOGOUT
} from "../actions/types";


export default function (state = {current:null, users:[] }, action) {
  const { type, payload } = action;
  //console.log(payload)
  switch (type) {
    case USER_GET_SUCCESS:
      return {
        ...state,
        current: payload
      };
    case USER_MODE_SUCCESS:
      return {
        ...state,
        users: payload
      };
    case USER_GET_FAIL:
      return state;
      case LOGOUT:
      return {
        current: null,
        users:[]
      };
    default:
      return state;
  }
}
