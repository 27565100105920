import React, { useEffect } from "react";
// Redux
import { useDispatch } from "react-redux";
// Bootstrap Components
import { Tabs, Tab } from "react-bootstrap";
import { getNotifications } from "../actions/admin.action";

import {
  NotificationList,
  NotificationListP,
} from "../components/NotificationList";

const Notification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the list products action and fill our state
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <div
      className="card shadow p-3 mt-3 boarduser"
      style={{
        maxWidth: "1024px",
        margin: "0 auto",
      }}
    >
      <div>
        <Tabs
          defaultActiveKey="notification"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="notification" title="InProgress">
            <NotificationList />
          </Tab>
          <Tab eventKey="notification_passive" title="Reviewed">
            <NotificationListP />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Notification;
