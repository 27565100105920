import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

// Redux
import { useDispatch, useSelector } from "react-redux";
// Bootstrap Components
import {
  InputGroup,
  FormControl,
  Alert,
  Table,
  Modal,
  Row,
  Col,
  ListGroup,
  Button,
} from "react-bootstrap";
import { getAllContracts } from "../actions/admin.action";

import Pagination from "../components/Pagination";

const AllContract = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [selected, setselected] = useState({});
  const [show, setShow] = useState(false);
  const [message, setmessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { contracts } = useSelector((store) => store.admins);

  const handleClose = () => setShow(false);
  const handleCloseX = () => setmessage("");

  useEffect(() => {
    dispatch(getAllContracts(currentPage, searchValue)).then((total) =>
      settotalItems(total)
    );
  }, [dispatch, currentPage, searchValue]);

  useEffect(() => {
    if (contracts) {
      setstate(contracts);
    }
  }, [contracts]);

  const onChangePage = (pageNum) => {
    setcurrentPage(pageNum);
  };

  const onFilterPageClear = (e) => {
    setsearchValue("");
  };

  function onRowClick(item) {
    const selectedcontract =
      contracts && contracts.find((elem) => elem._id === item._id);
    setselected(selectedcontract);
    setShow(true);
  }

  const handleSearch = (e) => {
    setsearchValue(e.target.value);
  };

  //console.log(state);
  //console.log(selected);
  return (
    <div style={{ maxWidth: "850px", margin: "0 auto" }}>
      <Col lg={6}>
        <InputGroup className="mb-2" >
          <InputGroup.Text>
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
          <FormControl
            id="contractAllSearch"
            type="text"
            placeholder="Search contract by companies..."
            name="searchValue"
            value={searchValue}
            onChange={handleSearch}
            size="sm"
          />
          <InputGroup.Text
            onClick={onFilterPageClear}
            style={{ cursor: "pointer" }}
            title="Clear"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ fontWeight: "bolder" }}>
            <th>Company</th>
            <th>User Name</th>
            {!isMobile && (
              <>
                <th>Contract Name</th>
                <th>HireDate</th>
                <th>EndDate</th>
              </>
            )}
            <th>Position</th>
          </tr>
        </thead>
        <tbody>
          {state ? (
            state.map((item, index) => {
              return (
                <tr key={item._id} onClick={() => onRowClick(item)}>
                  <td>{item.company}</td>
                  <td> 
                    {item &&
                      item.user_id &&
                      item.user_id.firstname.charAt(0).toUpperCase() +
                      item.user_id.firstname.slice(1) + 
                        " " +
                        item.user_id.middlename.charAt(0).toUpperCase() +
                        item.user_id.middlename.slice(1) +
                        " " +
                        item.user_id.lastname.charAt(0).toUpperCase() +
                        item.user_id.lastname.slice(1)}
                  </td>
                  {!isMobile && (
                    <>
                      <td>{item.contractname}</td>
                      <td>
                        {item.hireDate
                          ? new Date(item.hireDate).toLocaleDateString()
                          : ""}
                      </td>
                      <td>
                        {item.endDate ? (
                          new Date(item.endDate).toLocaleDateString()
                        ) : (
                          <Alert variant="info p-0">Continue</Alert>
                        )}
                      </td>
                    </>
                  )}
                  <td>{item.position}</td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {selected ? (
        <Modal
          size="md"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Alert variant={selected.endDate ? "dark" : "success"}>
                Contract Details
              </Alert>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <span>Contract Name</span>
              </Col>
              <Col sm={8}>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-signature"></i> {selected.contractname}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Company</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-building"></i> {selected.company}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>User Email</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-at"></i>{" "}
                    {selected && selected.user_id && selected.user_id.email}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>User Name</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-user"></i>{" "}
                    {selected &&
                      selected.user_id &&
                      selected.user_id.firstname.charAt(0).toUpperCase() +
                        selected.user_id.firstname.slice(1) +
                        " " +
                        selected.user_id.middlename.charAt(0).toUpperCase() +
                        selected.user_id.middlename.slice(1) +
                        " " +
                        selected.user_id.lastname.charAt(0).toUpperCase() +
                        selected.user_id.lastname.slice(1) }
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Position</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-crosshairs"></i> {selected.position}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Salary</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-hand-holding-usd"></i>{" "}
                    {selected.salary}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Details</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-percent"></i> Employment Basis :
                    {selected.employmentBasis}
                  </span>
                  <span>
                    <i className="fas fa-calendar-day"></i> Work Months :{" "}
                    {selected.workMonths}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>Hire Date</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-hourglass-start"></i>{" "}
                    {selected.hireDate
                      ? new Date(selected.hireDate).toLocaleDateString()
                      : ""}
                  </span>
                </ListGroup>
              </Col>
              <Col sm={4}>
                <hr className="mt-0"></hr>
                <span>End Date</span>
              </Col>
              <Col sm={8}>
                <hr className="mt-0"></hr>
                <ListGroup variant="flush">
                  <span>
                    <i className="fas fa-hourglass-end"></i>{" "}
                    {selected.endDate
                      ? new Date(selected.endDate).toLocaleDateString()
                      : ""}
                  </span>
                </ListGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div>
              {/* <Button
                variant="danger"
                className="p-1 btn2"
                size={isMobile && "sm"}
              >
                Delete
              </Button> */}
            </div>
            <div>
              <Button
                variant="secondary"
                className="p-1 btn2"
                size={isMobile && "sm"}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
      <Modal show={message ? true : false} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-success mt-2" role="alert">
            {message}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllContract;
